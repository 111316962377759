import React from "react";
import TopNav from "../reusables/TopNav";
import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import BackToTop from "../reusables/BackToTop";
import AboutBlock from "../reusables/AboutBlock";
import ProjectCompleted from "../reusables/ProjectCompleted";
import Faqs from "../reusables/Faqs";

function About() {
  return (
    <div className="flex w-full">
      <Header />
      <TopNav />
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2>About Us</h2>
            </div>
            <div class="col-12">
              <a href="">Home</a>
              <a href="">About Us</a>
            </div>
          </div>
        </div>
      </div>
      <AboutBlock />
      {/* <ProjectCompleted /> */}
      {/* <Faqs /> */}
      <Footer />
      <BackToTop />
    </div>
  );
}

export default About;
