// BackToTop.js
import React from "react";

function BackToTop() {
  const handleBackToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <div className="back-to-top" onClick={handleBackToTop}>
      <i className="fa fa-chevron-up"></i>
    </div>
  );
}

export default BackToTop;
