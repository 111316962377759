import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import sanityClient from "../client";
import "./Single.css";
import Header from "../reusables/Header";
import TopNav from "../reusables/TopNav";

function Single() {
  const [blogData, setBlogData] = useState([]);
  const navigate = useNavigate();

  // Use useEffect to fetch blog data when the component mounts
  useEffect(() => {
    // Define a query to fetch your blog data
    const query = `*[_type == "post"]{
      title,
      slug,
      mainImage{
          asset->{
            _id,
            url
          },
          alt
      },
      author->{name},
      categories->{title},
      body
    }`;

    // Use the Sanity client to execute the query
    sanityClient
      .fetch(query)
      .then((data) => {
        setBlogData(data);
        console.log("Fetched blog data:", data); // Added console log statement
      })
      .catch((error) => console.error("Error fetching blog data:", error));
  }, []);

  const { title } = useParams(); // Get the post ID from the URL params

  const selectedPost = blogData?.find((post) => post?.title === title);

  const handleBack = () => {
    // Use navigate(-1) to go back to the previous page
    navigate(-1);
  };

  if (!selectedPost) {
    return <div>Blog post not found</div>;
  }

  return (
    <>
      <Header />
      <TopNav />
      <div className="single-blog-container">
        <div className="blog-item">
          <div className="blog-img">
            <img
              src={selectedPost?.mainImage.asset.url}
              alt={selectedPost?.mainImage.alt}
            />
          </div>
          <div className="blog-details">
            <div className="blog-title">
              <h2>{selectedPost?.title}</h2>
            </div>
            <div className="blog-meta">
              <p>
                By <span>{selectedPost?.author.name}</span>
              </p>
              {selectedPost?.categories && (
                <p>
                  In <span>{selectedPost?.categories[0]?.title}</span>
                </p>
              )}
            </div>
            <div className="blog-text">
              <p>{selectedPost.body[0]?.children[0]?.text}</p>
            </div>
            <button className="back-button" onClick={handleBack}>
              Go Back
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Single;
