import React, { useState, useEffect } from "react";
import TopNav from "../reusables/TopNav";
import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import BackToTop from "../reusables/BackToTop";
import sanityClient from "../client"; // Make sure to import your Sanity client

function Portfolio() {
  const [portfolioData, setPortfolioData] = useState([]);

  useEffect(() => {
    const query = `*[_type == "portfolio"]{
      name,
      description,
      image {
        asset-> {
          _id,
          url
        }
      }
    }`;

    sanityClient
      .fetch(query)
      .then((data) => {
        setPortfolioData(data);
        console.log("Fetched portfolio data:", data);
      })
      .catch((error) => console.error("Error fetching portfolio data:", error));
  }, []);

  return (
    <div className="flex w-full">
      <Header />
      <TopNav />
      {/* ... existing code ... */}
      <div className="portfolio">
        <div className="container">
          <div className="section-header text-center">
            <p>Our Projects</p>
            <h2>Visit Our Projects</h2>
          </div>
          <div className="row portfolio-container">
            {portfolioData.map((project, index) => (
              <div
                key={index}
                className={`col-lg-4 col-md-6 col-sm-12 portfolio-item wow fadeInUp`}
                data-wow-delay={`${0.1 * (index + 1)}s`}
              >
                <div className="portfolio-warp">
                  <div className="portfolio-img">
                    <img
                      src={project.image.asset.url}
                      alt={project.name}
                    />
                    <div className="portfolio-overlay">
                      <p>{project.description}</p>
                    </div>
                  </div>
                  <div className="portfolio-text">
                    <h3>{project.name}</h3>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />
      <BackToTop />
    </div>
  );
}

export default Portfolio;
