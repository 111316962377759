import React from "react";
import TopNav from "../reusables/TopNav";
import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import BackToTop from "../reusables/BackToTop";
import Faqs from "../reusables/Faqs";
import ServicesBlock from "../reusables/ServicesBlock";

function Service() {
  return (
    <div className="flex w-full">
      <Header />
      <TopNav />
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2>Our Services</h2>
            </div>
            <div class="col-12">
              <a href="">Home</a>
              <a href="">Our Services</a>
            </div>
          </div>
        </div>
      </div>
      <ServicesBlock />
      {/* <Faqs /> */}
      <Footer />
      <BackToTop />
    </div>
  );
}

export default Service;
