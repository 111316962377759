import React, { useEffect, useState } from 'react';
import sanityClient from '../client';
import './ServicesBlock.css'; // Import your stylesheet

function ServicesBlock() {
  // State to store the fetched service data
  const [data, setData] = useState([]);

  // Use useEffect to fetch service data when the component mounts
  useEffect(() => {
    // Define a query to fetch your service data
    const query = `*[_type == "service"]{
      name,
      image{
        asset->{
          _id,
          url
        },
        alt
      },
      description
    }`;

    // Use the Sanity client to execute the query
    sanityClient
      .fetch(query)
      .then((data) => {
        setData(data);
        console.log('Fetched service data:', data);
      })
      .catch((error) => console.error('Error fetching service data:', error));
  }, []);

  return (
    <div className="service">
      <div className="container">
        <div className="section-header text-center">
          <p>Our Services</p>
          <h2>We Provide Services</h2>
        </div>
        <div className="row">
          {data.map((service) => (
            <div
              key={service._id} // Make sure to replace with the actual ID field from your schema
              className="col-lg-4 col-md-6 wow fadeInUp service-item-wrapper"
              data-wow-delay="0.1s"
            >
              <div className="service-item">
                <div className="service-img">
                  <img src={service.image.asset.url} alt={service.image.alt} />
                  <div className="service-overlay">
                    <p>{service.description}</p>
                  </div>
                </div>
                <div className="service-text">
                  <h3>{service.name}</h3>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ServicesBlock;
