import React, { useEffect, useState } from "react";
import sanityClient from "../client";

function AboutBlock() {
  const [data, setAboutData] = useState(null);

  // Fetching data and logging it
  useEffect(() => {
    const query = `*[_type == "about"][0]{
    title,
    experience,
    content,
    "image": image.asset->url
  }`;

    sanityClient
      .fetch(query)
      .then((data) => {
        setAboutData(data);
        console.log("Fetched about data:", data); // Added console log statement
      })
      .catch((error) => console.error("Error fetching about data:", error));
  }, []);

  return (
    <div className="about wow fadeInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6">
            <div className="about-img">
              <img src={data?.image} alt={data?.title} />
            </div>
          </div>
          <div className="col-lg-7 col-md-6">
            <div className="section-header text-left">
              <p className="text-center">{data?.title}</p>
              <h2>{data?.experience}</h2>
            </div>
            <div className="about-text">
              {data?.content.map((paragraph, index) => (
                <p key={index}>{paragraph}</p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutBlock;
