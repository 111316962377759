import "./App.css";
import { Link } from 'react-router-dom';
import TopNav from "./reusables/TopNav";
import Header from "./reusables/Header";
import AboutBlock from "./reusables/AboutBlock";
import ProjectCompleted from "./reusables/ProjectCompleted";
import ServicesBlock from "./reusables/ServicesBlock";
import TeamBlock from "./reusables/TeamBlock";
import Faqs from "./reusables/Faqs";
import BlogBlock from "./reusables/BlogBlock";
import Footer from "./reusables/Footer";
import BackToTop from "./reusables/BackToTop";
import Blog from "./components/Blog";
import sanityClient from "./client"; 
import React, { useEffect, useState } from "react";
import Carousel from "./reusables/Carousel";
import FeatureBlock from "./reusables/FeatureBlock";

function App() {
    // State to store the fetched blog data
    const [blogData, setBlogData] = useState([]);

    // Use useEffect to fetch blog data when the component mounts
    useEffect(() => {
      // Define a query to fetch your blog data
      const query = `*[_type == "post"]{
      title,
      slug,
      mainImage{
          asset->{
            _id,
            url
          },
          alt
      },
      author->{name},
      categories->{title},
      body
    }`;
  
      // Use the Sanity client to execute the query
      sanityClient
        .fetch(query)
        .then((data) => {
          setBlogData(data);
          console.log("Fetched blog data:", data); // Added console log statement
        })
        .catch((error) => console.error("Error fetching blog data:", error));
    }, []);

  return (
    <div className="flex w-full">
      <Header />
      <TopNav />
      <Carousel />
      <FeatureBlock />
      <AboutBlock/>
      <ServicesBlock />
      <div
        className="modal fade"
        id="videoModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-body">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src=""
                  id="video"
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BlogBlock blogData={blogData} />
      <Footer />
      <BackToTop />
    </div>
  );
}

export default App;
