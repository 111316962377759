import React, { useEffect, useState } from 'react';
import sanityClient from '../client';

function FeatureBlock() {
  // State to store the fetched feature data
  const [data, setData] = useState([]);

  // Use useEffect to fetch feature data when the component mounts
  useEffect(() => {
    // Define a query to fetch your feature data
    const query = `*[_type == "featureItem"]{
      icon,
      title,
      description
    }`;

    // Use the Sanity client to execute the query
    sanityClient
      .fetch(query)
      .then((data) => {
        setData(data);
        console.log('Fetched feature data:', data);
      })
      .catch((error) => console.error('Error fetching feature data:', error));
  }, []);

  return (
    <div className="feature wow fadeInUp" data-wow-delay="0.1s">
      <div className="container-fluid">
        <div className="row align-items-center">
          {data.map((feature, index) => (
            <div key={index} className="col-lg-6 col-md-12">
              <div className="feature-item">
                <div className="feature-icon">
                  <i className={feature.icon}></i>
                </div>
                <div className="feature-text">
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default FeatureBlock;
