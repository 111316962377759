import React, { useEffect, useState } from "react";
import sanityClient from "../client"; 
import TopNav from "../reusables/TopNav";
import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import BackToTop from "../reusables/BackToTop";
import BlogBlock from "../reusables/BlogBlock";

function Blog() {
  // State to store the fetched blog data
  const [blogData, setBlogData] = useState([]);

  // Use useEffect to fetch blog data when the component mounts
  useEffect(() => {
    // Define a query to fetch your blog data
    const query = `*[_type == "post"]{
    title,
    slug,
    mainImage{
        asset->{
          _id,
          url
        },
        alt
    },
    author->{name},
    categories->{title},
    body
  }`;

    // Use the Sanity client to execute the query
    sanityClient
      .fetch(query)
      .then((data) => {
        setBlogData(data);
        console.log("Fetched blog data:", data); // Added console log statement
      })
      .catch((error) => console.error("Error fetching blog data:", error));
  }, []);

  return (
    <div className="flex w-full">
      {/* Your existing JSX code */}
      <Header />
      <TopNav />
      <div className="page-header">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2>Our Blog</h2>
            </div>
            <div className="col-12">
              <a href="">Home</a>
              <a href="">Our Blog</a>
            </div>
          </div>
        </div>
      </div>
      <BlogBlock blogData={blogData} />

      <Footer />
      <BackToTop />
    </div>
  );
}

export default Blog;
