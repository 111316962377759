import React from 'react';
import { Link } from 'react-router-dom';
import '../components/BlogBlock.css';

function BlogBlock({ blogData }) {
  console.log('blogData', blogData);
  return (
    <div className="blog">
      <div className="container">
        <div className="section-header text-center">
          <p>Latest Blog</p>
          <h2>Latest From Our Blog</h2>
        </div>
        <div className="row">
          {blogData &&
            blogData.map((post) => (
              <div
                key={post?._id}
                className="col-lg-4 col-md-6 wow fadeInUp blog-item-wrapper"
                data-wow-delay="0.2s"
              >
                <Link to={`/single/${post?.title}`} className="blog-item-link">
                  <div className="blog-item">
                    <div className="blog-img">
                      <img src={post?.mainImage.asset.url} alt={post?.mainImage.alt} />
                    </div>
                    <div className="blog-title">
                      <h3>{post?.title}</h3>
                    </div>
                    <div className="blog-meta">
                      <p>
                        By <span>{post?.author.name}</span>
                      </p>
                      {post?.categories && (
                        <p>
                          In <span>{post?.categories[0]?.title}</span>
                        </p>
                      )}
                    </div>
                    <div className="blog-text">
                      <p>{post.body[0]?.children[0]?.text}</p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default BlogBlock;
