import React, { useRef, useState, useEffect } from "react";
import TopNav from "../reusables/TopNav";
import Header from "../reusables/Header";
import Footer from "../reusables/Footer";
import BackToTop from "../reusables/BackToTop";
import emailjs from "@emailjs/browser";
import sanityClient from "../client";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Contact() {
  const form = useRef();
  const [data, setData] = useState({});

  useEffect(() => {
    const query = `*[_type == "header"][0]{
      "logo": logo.asset->url,
      openingHour,
      callUs,
      emailUs,
      address
    }`;

    sanityClient
      .fetch(query)
      .then((data) => {
        setData(data);
        console.log("Fetched header data:", data);
      })
      .catch((error) => console.error("Error fetching header data:", error));
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();

    // Client-side form validation
    const name = form.current.querySelector('[name="from_name"]').value;
    const email = form.current.querySelector('[name="email"]').value;
    const subject = form.current.querySelector('[name="subject"]').value;
    const message = form.current.querySelector('[name="message"]').value;

    // Email format validation using a simple regular expression
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!name || !email || !subject || !message) {
      // Display error message if any field is empty
      toast.error("Please fill in all fields with valid information", {
        position: "top-right",
      });
      return;
    }

    if (!emailRegex.test(email)) {
      // Display error message if email format is invalid
      toast.error("Please enter a valid email address", {
        position: "top-right",
      });
      return;
    }

    // Proceed with sending the email if all fields are filled and email is valid
    emailjs
      .sendForm(
        "service_h8i901y",
        "template_oktvu5b",
        form.current,
        "47ns7WoZ0YTDJEfs7"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message sent!", { position: "top-right" });

          // Clear the form after successful submission
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="flex w-full">
      <Header />
      <TopNav />
      <div class="page-header">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <h2>Contact Us</h2>
            </div>
            <div class="col-12">
              <a href="">Home</a>
              <a href="">Contact Us</a>
            </div>
          </div>
        </div>
      </div>
      <div class="contact wow fadeInUp">
        <div class="container">
          <div class="section-header text-center">
            <p>Get In Touch</p>
            <h2>For Any Query</h2>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="contact-info">
                <div class="contact-item">
                  <i class="flaticon-address"></i>
                  <div class="contact-text">
                    <h2>Location (Canada)</h2>
                    <p>{data?.address}</p>
                  </div>
                </div>
                <div class="contact-item">
                  <i class="flaticon-address"></i>
                  <div class="contact-text">
                    <h2>Location (Kinshasa, DRC)</h2>
                    <p>
                      Congo DRC, Kinshasa office 8 Chemin des Dames Ma Campagne{" "}
                    </p>
                  </div>
                </div>
                <div class="contact-item">
                  <i class="flaticon-call"></i>
                  <div class="contact-text">
                    <h2>Phone (Canada)</h2>
                    <p>{data?.callUs}</p>
                  </div>
                </div>
                <div class="contact-item">
                  <i class="flaticon-call"></i>
                  <div class="contact-text">
                    <h2>Phone (Kinshasa, DRC)</h2>
                    <p>0 813 336 591</p>
                  </div>
                </div>
                <div class="contact-item">
                  <i class="flaticon-send-mail"></i>
                  <div class="contact-text">
                    <h2>Email</h2>
                    <p>{data?.emailUs}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="contact-form">
                <div id="success"></div>
                <form
                  ref={form}
                  onSubmit={sendEmail}
                  name="sentMessage"
                  id="contactForm"
                  novalidate="novalidate"
                >
                  <div class="control-group">
                    <input
                      type="text"
                      class="form-control"
                      id="name"
                      placeholder="Your Name"
                      required="required"
                      data-validation-required-message="Please enter your name"
                      name="from_name"
                    />
                    <p class="help-block text-danger"></p>
                  </div>
                  <div class="control-group">
                    <input
                      type="email"
                      class="form-control"
                      id="email"
                      placeholder="Your Email"
                      required="required"
                      data-validation-required-message="Please enter your email"
                      name="email"
                    />
                    <p class="help-block text-danger"></p>
                  </div>
                  <div class="control-group">
                    <input
                      type="text"
                      class="form-control"
                      id="subject"
                      placeholder="Subject"
                      required="required"
                      data-validation-required-message="Please enter a subject"
                      name="subject"
                    />
                    <p class="help-block text-danger"></p>
                  </div>
                  <div class="control-group">
                    <textarea
                      class="form-control"
                      id="message"
                      placeholder="Message"
                      required="required"
                      data-validation-required-message="Please enter your message"
                      name="message"
                    ></textarea>
                    <p class="help-block text-danger"></p>
                  </div>
                  <div>
                    <button
                      class="btn"
                      type="submit"
                      id="sendMessageButton"
                      value="Send"
                    >
                      Send Message
                    </button>
                  </div>
                </form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <BackToTop />
    </div>
  );
}

export default Contact;
