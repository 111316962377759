import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client";

function Carousel() {
  // State to store the fetched carousel data
  const [data, setData] = useState([]);

  // Use useEffect to fetch carousel data when the component mounts
  useEffect(() => {
    // Define a query to fetch your carousel data
    const query = `*[_type == "carouselItem"]{
      image{
        asset->{
          _id,
          url
        },
        alt
      },
      caption,
      title,
      buttonText,
      buttonLink
    }`;

    // Use the Sanity client to execute the query
    sanityClient
      .fetch(query)
      .then((data) => {
        setData(data);
        console.log("Fetched carousel data:", data);
      })
      .catch((error) => console.error("Error fetching carousel data:", error));
  }, []);

  return (
    <div id="carousel" className="carousel slide" data-ride="carousel">
      <ol className="carousel-indicators">
        {data.map((item, index) => (
          <li
            key={index}
            data-target="#carousel"
            data-slide-to={index}
            className={index === 0 ? "active" : ""}
          ></li>
        ))}
      </ol>
      <div className="carousel-inner">
        {data.map((item, index) => (
          <div
            key={index}
            className={`carousel-item ${index === 0 ? "active" : ""}`}
          >
            <img src={item.image.asset.url} alt={item.image.alt} />
            <div className="carousel-caption">
              <p className="animated fadeInRight">{item.caption}</p>
              <h1 className="animated fadeInLeft">{item.title}</h1>
            </div>
          </div>
        ))}
      </div>
      <a
        className="carousel-control-prev"
        href="#carousel"
        role="button"
        data-slide="prev"
      >
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="sr-only">Previous</span>
      </a>
      <a
        className="carousel-control-next"
        href="#carousel"
        role="button"
        data-slide="next"
      >
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
}

export default Carousel;
