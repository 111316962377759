import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();
  const phoneNumber = "+27743649792";
  const emailAddress = "hervetusse@gmail.com";
  return (
    <div className="footer wow fadeIn" data-wow-delay="0.3s">
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-3">
            <div className="footer-contact">
              <h2>Office Contact</h2>
              <p>
                <i className="fa fa-map-marker-alt"></i>Canadian Head Office
                2389 Equestrian Crescent Oshawa, ON L1L 0L6
              </p>
              <p>
                <i className="fa fa-map-marker-alt"></i>Congo DRC, Kinshasa
                office 8 Chemin des Dames Ma Campagne
              </p>
              <p>
                <i className="fa fa-phone-alt"></i>+1 905-492-0078
              </p>
              <p>
                <i className="fa fa-phone-alt"></i>0 813 336 591
              </p>
              <p>
                <i className="fa fa-envelope"></i>contact@esorpainc.com
              </p>

              <div className="footer-social">
                <a href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="">
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="">
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footer-link">
              <h2>Services Areas</h2>
              <Link>Mining</Link>
              <Link>⁠Construction</Link>
              <Link>⁠Transport</Link>
              <Link>⁠Environment</Link>
              <Link>Risk and Project Management</Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="footer-link">
              <h2>Useful Pages</h2>
              <Link to="/about">About Us</Link>
              <Link to="/contact">Contact Us</Link>
            </div>
          </div>
          <div className="col-md-6 col-lg-3">
            <div className="newsletter">
              <h2>Newsletter</h2>
              <p>📬 Subscribe for Exclusive Updates!</p>
              <div className="form">
                <input className="form-control" placeholder="Email here" />
                <button className="btn">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container footer-menu">
        <div className="f-menu">
          {/* <Link >Terms of use</Link>
          <Link >Privacy policy</Link>
          <Link >Cookies</Link>
          <Link >Help</Link>
          <Link >FQAs</Link> */}
        </div>
      </div>
      <div className="container copyright">
        <div className="row">
          <div className="col-md-6">
            <p>
              &copy; <a to="#">{currentYear} ESORPA INC</a>, All Right Reserved.
            </p>
          </div>
          <div className="col-md-6">
            <p>
              Designed By <a href={`mailto:${emailAddress}`}>XcelleceTech</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
