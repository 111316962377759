import React, { useEffect, useState } from 'react';
import sanityClient from '../client';

function TeamBlock() {
  // State to store the fetched team data
  const [data, setData] = useState([]);

  // Use useEffect to fetch team data when the component mounts
  useEffect(() => {
    // Define a query to fetch your team data
    const query = `*[_type == "teamMember"]{
      name,
      role,
      image{
        asset->{
          _id,
          url
        },
        alt
      },
      socialLinks
    }`;

    // Use the Sanity client to execute the query
    sanityClient
      .fetch(query)
      .then((data) => {
        setData(data);
        console.log('Fetched team data:', data);
      })
      .catch((error) => console.error('Error fetching team data:', error));
  }, []);

  return (
    <div className="team">
      <div className="container">
        <div className="section-header text-center">
          <p>Our Team</p>
          <h2>Meet Our Engineers</h2>
        </div>
        <div className="row">
          {data.map((teamMember) => (
            <div
              key={teamMember._id} // Make sure to replace with the actual ID field from your schema
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item">
                <div className="team-img">
                  <img src={teamMember.image.asset.url} alt={teamMember.image.alt} />
                </div>
                <div className="team-text">
                  <h2>{teamMember.name}</h2>
                  <p>{teamMember.role}</p>
                </div>
                <div className="team-social">
                  {teamMember?.socialLinks?.map((link, index) => (
                    <a key={index} href={link.url} className={`social-${link.type}`} target="_blank" rel="noopener noreferrer">
                      <i className={`fab fa-${link.type.toLowerCase()}`}></i>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default TeamBlock;
